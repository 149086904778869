import React from "react";
import Slider from "react-slick";

function ActiveSlick() {
  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "60px",
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="w-full overflow-hidden">
      <Slider {...settings}>
        {[
          "activity-00001.jpg",
          "activity-00002.jpg",
          "activity-00003.jpg",
          "activity-00004.jpg",
          "activity-00005.jpg",
          "activity-00006.jpg",
          "activity-00007.jpg",
          "activity-00008.jpg",
          "activity-00009.jpg",
          "activity-00010.jpg",
          "activity-00011.jpg",
          "activity-00012.jpg",
          "activity-00013.jpg",
          "activity-00014.jpg",
          "activity-00015.jpg",
          "activity-00016.jpg",
          "activity-00017.jpg",
          "activity-00018.jpg",
        ].map((item, index) => {
          return (
            <div key={index}>
              <img
                src={require(`../../assets/img/activity/${item}`)}
                className="object-cover w-[360px] h-[240px] rounded-[24px]"
                alt=""
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default ActiveSlick;

import React from "react";

export function Logo(props: any) {
  return (
    <a href="" {...props}>
      <img
        className="w-[48px] h-[48px]"
        src={require("../../assets/img/logo/logo256.png")}
        alt=""
      />
    </a>
  );
}

import React from "react";
import NavLink from "../NavLink";
import { Logo } from "./Logo";

const Footer = () => {
  return (
    <footer className="max-w-screen-xl px-8 mx-auto">
      <div className="w-full border-y border-[#DDDDDD]">
        <div className="grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
          <div className="md:w-full lg:w-full w-full sm:w-1/2 xl:w-fit flex flex-col sm:flex-row sm:px-6 py-6 sm:py-12 sm:space-x-10 sm:border-r-0 border-[#DDDDDD]">
            <div className="sm:hidden xl:block mb-6 sm:mb-0">
              <a href="#">
                <Logo />
              </a>
            </div>
            <ul className="space-y-4">
              <NavLink name="首页" url="" />
              <NavLink name="公益脱单" url="#welfare" />
              <NavLink name="付费脱单" url="#choose" />
              <NavLink name="关注我们" url="#about" />
              <NavLink name="支持" url="#support" />
            </ul>
          </div>
          <div className="md:w-full lg:w-full lg:border-r lg:border-t-0 w-full sm:w-1/2 xl:w-fit sm:px-16 py-6 sm:py-12  sm:border-t xl:border-r border-[#DDDDDD]">
            <ul className="space-y-4">
              <NavLink
                name="关于她他"
                target="_blank"
                url="https://mp.weixin.qq.com/s/PF-xoIbmyaPz3a_XB2M0OA"
              />
              <NavLink
                name="用户协议"
                target="_blank"
                url="https://tatatest.hv68.cn/index.html#/auth/policy?type=user"
              />
              <NavLink
                name="隐私协议"
                target="_blank"
                url="https://tatatest.hv68.cn/index.html#/auth/policy?type=user"
              />
            </ul>
          </div>
          <div className="md:w-full md:border-t lg:w-full w-full sm:w-1/2 xl:w-fit sm:px-16 py-6 sm:py-12  sm:border-t-0 sm:border-r-0 lg:border-t-0 border-[#DDDDDD]">
            <ul className="space-y-4">
              <NavLink name="帮助中心" url="#about" />
              <NavLink name="联系我们" url="#about" />
            </ul>
          </div>
          <div className="md:w-full md:border-t lg:w-full lg:border-t-0 sm:px-10 py-6 sm:py-12 w-full sm:w-1/2 xl:w-[22rem] space-y-4 sm:border-t border-[#DDDDDD]">
            <h5 className="text-sm font-medium text-[#666666] focus:outline-none focus:shadow-outline">
              她他友心人服务号
            </h5>
            <p className="text-sm text-[#666666] focus:outline-none focus:shadow-outline">
              接受互选通知， <br className="sm:hidden" />
              不错过优秀的活动和她/他
            </p>
            <div>
              <img
                src={require(`../../assets/img/getting-started/tata-qrcode.png`)}
                className="max-w-[245px] mx-auto -ml-[16px]"
                alt=""
              />
            </div>
            {/* <div className="flex items-center space-x-2">
              <input
                type="text"
                className="w-full px-2 py-4 sm:py-3 rounded-lg sm:rounded-md text-sm focus:outline-none border border-[#AAAAAA] placeholder-[#888]"
                placeholder="Enter your email"
              />
              <button className="bg-blue-gradient px-4 py-4 sm:py-3 rounded-md text-white hover:shadow-md transition duration-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="py-8 sm:py-4 text-center text-sm text-[#666666] hover:text-gray-900">
        &copy;
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
          Copyright 2023 她他之恋 粤ICP备2020140762号-1
        </a>
      </div>
    </footer>
  );
};

export default Footer;

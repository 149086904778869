/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
// Layouts
import DefaultLayout from "./layouts/default";
// Components
import BaseButton from "./components/base/Button";
import BaseSection from "./components/base/Section";
import BaseAccordion from "./components/base/Accordion";
import LandingListItem from "./components/landing/ListItem";
import LandingStep from "./components/landing/Step";
import ActiveSlick from "./components/base/ActiveSlick";

function App() {
  const steps = [
    {
      img: "tata-qrcode.png",
      title: "服务号",
      description:
        "定期发布各类活动、分享优质嘉宾。关注我们的服务号，接受互选通知，不错过优秀的活动和她/他",
    },
    {
      img: "h5qr.png",
      title: "H5 社区",
      description:
        "平台汇聚海归、教师、公务员、律师等众多优秀单身男女，加入 H5 脱单社区，早日遇到命中注定的那个她/他",
    },
    {
      img: "yuelao.png",
      title: "月老一线牵",
      description:
        "添加月老微信，为你制造社交机会，帮助你丰富交际。有了月老，相亲约会就是这么简单。",
    },
  ];

  const accordions = [
    {
      title: "我应该使用什么样的照片？",
      description:
        "你上传的照片应该包含大家都想看到的人，那就是你！请舍弃你与朋友的合照吧，因为大家想看的不是他们；另外确保照片中你没戴太阳眼镜，因为它们会遮挡你的面庞。突显你的照片才是最好的照片，另外听说面带笑容的照片在这里很惹眼哦。",
    },
    {
      title: "我应该在个人资料中放什么内容？",
      description:
        "你的个人资料相当于你的一张快照。如果你不擅长写介绍文字，你可以列出你喜欢或想要的事物。你可以简洁列出你的兴趣，或深入表达你想在配对中收获怎样的关系。至于哪些信息不宜添加到个人资料中，请查阅我们的社群规则。",
    },
    {
      title: "如何取消与某人的配对？",
      description:
        "如果你和对配对对象不来电怎么办？有办法。你可以随时和任何配对对象取消配对。在配对对象个人资料页点击红色爱心图标即可取消配对。",
    },
    {
      title: "平台上的个人资料是真实的吗？",
      description:
        "平台要求提供照片验证来确保你的聊天对象与他们照片中的人一致。通过照片验证的个人资料将会带有一个打勾标示，我们强烈建议你进行验证，因为这个蓝色标示可以为你的个人资料增色，还能让我们的社区更加安全。",
    },
    {
      title: "我可以在平台上找到约会对象吗？",
      description:
        "她他之恋已成功达成上百个配对，因此在她他之恋上结识新朋友再简单不过了。我们推出了许多特色功能，旨在让你脱颖而出，提升你的配对几率。至于是否要主动出击，决定权永远在你手上。",
    },
    {
      title: "我可以在平台上找到真爱吗？",
      description:
        "如果你想寻找真爱，那么选择她他之恋就对了！在她他之恋，一切皆有可能，很多婚礼誓言可以为她他之恋作证哦。",
    },
  ];

  return (
    <div className="App">
      <DefaultLayout>
        {/* <!-- Hero section --> */}
        <section id="hero" className="w-full pb-24">
          <BaseSection>
            <div className="col-span-12 lg:col-span-6 mt-12 xl:mt-10 space-y-4 sm:space-y-6 px-6 text-center sm:text-left">
              <span
                data-aos="fade-right"
                data-aos-once="true"
                className="text-base text-gradient font-semibold uppercase"
              >
                她他之恋，单身自救
              </span>
              <h1
                data-aos="fade-right"
                data-aos-once="true"
                className="text-[2.5rem] sm:text-5xl xl:text-6xl font-bold !leading-tight capitalize sm:pr-8 xl:pr-10"
              >
                中高端的 <br />
                <span className="text-header-gradient">
                  有温度有情怀的
                </span>{" "}
                <br />
                单身自救互助平台
              </h1>
              <p
                data-aos="fade-down"
                data-aos-once="true"
                data-aos-delay="300"
                className="paragraph hidden sm:block"
              >
                她他之恋于 2019 年 8 月 8
                日诞生在深圳大学，我们希望通过自己的努力自救脱单，同时让过度商业化的交友市场有一片温暖友爱的天空。
              </p>
              <div
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="700"
                className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-2"
              >
                <BaseButton style="max-w-full px-8 py-4 bg-gradient-to-r from-[#468ef9] to-[#0c66ee] border border-[#0c66ee] text-white">
                  成为会员
                </BaseButton>
                <BaseButton style="max-w-full px-6 py-4 gap-1 bg-inherit text-gradient border border-[#0c66ee] flex items-center justify-center">
                  <span>关注我们</span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-[#0c66ee]"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </BaseButton>
              </div>
            </div>
            <div className="hidden sm:block col-span-12 lg:col-span-6">
              <div className="w-full">
                <img
                  data-aos="fade-up"
                  data-aos-once="true"
                  src={require("./assets/img/public-and-private.png")}
                  className="-mt-4"
                  alt="hero image"
                />
              </div>
            </div>
            <img
              alt=""
              data-aos="fade-up"
              data-aos-delay="300"
              src={require("./assets/img/pattern/ellipse-1.png")}
              className="hidden sm:block absolute bottom-12 xl:bottom-16 left-4 xl:left-0 w-6"
            />
            <img
              alt=""
              data-aos="fade-up"
              data-aos-delay="300"
              src={require("./assets/img/pattern/ellipse-2.png")}
              className="hidden sm:block absolute top-4 sm:top-10 right-64 sm:right-96 xl:right-[32rem] w-6"
            />
            <img
              alt=""
              data-aos="fade-up"
              data-aos-delay="300"
              src={require("./assets/img/pattern/ellipse-3.png")}
              className="hidden sm:block absolute bottom-56 right-24 w-6"
            />
            <img
              alt=""
              data-aos="fade-up"
              data-aos-delay="300"
              src={require("./assets/img/pattern/star.png")}
              className="hidden sm:block absolute top-20 sm:top-28 right-16 lg:right-0 lg:left-[30rem] w-8"
            />
          </BaseSection>
        </section>
        {/* <!-- Crypto statistic section --> */}
        <section
          data-aos="fade-up"
          className="max-w-screen-xl mx-2 sm:mx-auto px-4 sm:px-6 lg:px-0 py-6 pb-20 sm:py-8 rounded-[2.25rem] sm:rounded-xl bg-white shadow-lg sm:shadow-md transform lg:-translate-y-12"
        >
          <div className="w-full flex flex-col lg:flex-row items-center justify-center">
            {/* <LandingCryptoStatistic
              title="🔥 Trending"
              datasets={trendings}
              style="xl:border-r border-gray-200 lg:px-8"
            />
            <LandingCryptoStatistic
              title="🚀 Top Gainers"
              datasets={topGainers}
              style="xl:border-r border-gray-200 lg:px-8"
            />
            <LandingCryptoStatistic
              title="💎 Recently Added"
              datasets={recents}
              style="lg:px-8"
            /> */}
            <ActiveSlick />
          </div>
        </section>

        {/* <!-- 公益脱单 --> */}
        <section className="w-full my-24" id="welfare">
          <BaseSection>
            <div className="col-span-12 lg:col-span-6 sm:hidden mb-8">
              <div className="w-full">
                <img
                  src={require("./assets/img/buy-and-trade.webp")}
                  className="mt-4 sm:-mt-4"
                  alt=""
                />
              </div>
            </div>
            <div
              data-aos="fade-right"
              className="col-span-12 lg:col-span-6 mt-4 xl:mt-20 space-y-6 px-4"
            >
              <h2 className="text-4xl font-semibold sm:pr-8 xl:pr-12 lg:leading-tight">
                <span className="text-header-gradient">公益脱单攻略</span>{" "}
                <br className="hidden sm:block" />
                免费提供各类服务，行善积德
              </h2>
              <p className="paragraph">
                让单身小伙伴降低脱单成本，让冰冷的城市增加更多的温度，让紧张的人与人间的关系增加更多的友谊，一起传递友谊、爱与正能量，让更多的单身小伙伴收益幸福。
              </p>
              <div className="space-y-6 lg:pr-12">
                <img
                  src={require("./assets/img/welfare/rose.png")}
                  alt=""
                  className="border border-pink-100 rounded-[32px] pointer-events-auto hover:scale-[101%]"
                />
              </div>
              {/* <div className="space-y-6 lg:pr-12">
                <LandingExchange
                  title="Amount"
                  name="amount"
                  type="number"
                  defaultValue="5.000"
                  exchangeSelected={currencySelected}
                  exchanges={currencies}
                />
                <LandingExchange
                  title="Get"
                  name="get"
                  type="number"
                  defaultValue="0.10901"
                  exchangeSelected={cryptoSelected}
                  exchanges={cryptocurrencies}
                />
                <BaseButton style="w-full px-5 py-4 bg-blue-gradient text-white text-base font-medium">
                  Buy Now
                </BaseButton>
              </div> */}
            </div>
            <div
              data-aos="fade-left"
              className="col-span-12 lg:col-span-6 hidden sm:block mt-16"
              v-bind="$attrs"
            >
              <div className="w-full flex items-center justify-center">
                <img
                  src={require("./assets/img/balloon.png")}
                  style={{ zoom: 0.5 }}
                  alt=""
                />
              </div>
            </div>
          </BaseSection>
        </section>
        {/* <!-- Partners section --> */}
        <section className="bg-partner relative max-w-full sm:mx-6 my-24 shadow sm:rounded-2xl overflow-hidden">
          <div className="w-full px-6 sm:px-0 py-16 flex flex-col items-center justify-center space-y-4 text-center">
            <h3
              data-aos="flip-down"
              className="text-2xl text-neutral-800 font-semibold"
            >
              公益列表
            </h3>
            <p data-aos="flip-down" className="paragraph">
              我们为大家提供了各种免费的公益服务
            </p>
            <div
              data-aos="fade-up"
              className="flex flex-wrap items-start justify-center gap-8"
            >
              {["01.png", "02.png", "03.png", "04.png"].map((img) => {
                return (
                  <img
                    src={require(`./assets/img/welfare/${img}`)}
                    className="sm:w-1/2 lg:w-72 mx-auto border border-gray-100 rounded-lg hover:shadow-md pointer-events-auto"
                    alt=""
                  />
                );
              })}
            </div>
          </div>
        </section>
        {/* <!-- Credit card section --> */}
        <section className="w-full my-36" id="choose">
          <BaseSection data-aos="fade-down">
            <div className="col-span-12 lg:col-span-7">
              <div className="w-full">
                <img
                  src={require("./assets/img/public-and-private.png")}
                  className="w-[95%]"
                  alt=""
                />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-5 space-y-6 px-4 sm:px-6 mt-20">
              <h2 className="text-4xl font-semibold">
                主动
                <span className="text-header-gradient">公域/私域</span>互选
              </h2>
              <p className="paragraph">可以选择按次付费或者按时间付费</p>
              <ul className="space-y-4 sm:space-y-2">
                <LandingListItem title="全平台推送，被更多人发现，加速脱单">
                  {" "}
                </LandingListItem>
                <LandingListItem title="加入高端社群（前提是符合条件）">
                  {" "}
                </LandingListItem>
                <LandingListItem title="线上互选，不限人数互选均免费">
                  {" "}
                </LandingListItem>
              </ul>
              <BaseButton style="w-full mt-8 sm:max-w-[240px] px-10 py-4 bg-inherit text-gradient border border-[#0c66ee] text-base">
                我要加入
              </BaseButton>
            </div>
          </BaseSection>
        </section>
        {/* <!-- Advanced trading tools section --> */}
        <section className="bg-trading-tools relative max-w-full sm:mx-4 my-20 py-16 shadow rounded-2xl overflow-hidden">
          <div className="relative max-w-screen-xl px-4 sm:px-2 mx-auto grid grid-cols-12 gap-x-6">
            <div className="col-span-12 lg:col-span-6 sm:hidden">
              <div className="w-full sm:mt-20 xl:mt-0">
                <img
                  src={require(`./assets/img/advanced-trading-tools.webp`)}
                  className="w-full"
                  alt=""
                />
              </div>
            </div>
            <div
              data-aos="fade-right"
              className="col-span-12 lg:col-span-6 space-y-8 sm:space-y-6 px-4 sm:px-6 mt-8"
            >
              <h2 className="text-4xl font-semibold">
                付费
                <span className="text-header-gradient">相亲</span>活动
              </h2>
              <div className="space-y-2">
                <h4 className="text-lg font-medium">首创王牌 | 脱单CP双保险</h4>
                <p className="paragraph text-sm xl:text-base">
                  线上互选，看脸看条件拒绝浪费时间；
                  线下面基，三观一致了见面才有意义。
                  按时间付费/按次数付费（不成功不收费）。
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-lg font-medium">
                  联谊活动 | 大型联谊相亲活动
                </h4>
                <p className="paragraph text-sm xl:text-base">
                  和各大高校、企业、平台联谊举办大型的交友相亲活动，更大程度的增加脱单的成功率。
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-lg font-medium">
                  常规活动 | 饭局、羽毛球、剧本杀等
                </h4>
                <p className="paragraph text-sm xl:text-base">
                  有时间就来！我们定期举办常规活动，欢迎你的踊跃参与。
                </p>
              </div>
              <div className="flex flex-col sm:flex-row">
                <BaseButton style="px-10 py-4 bg-inherit text-gradient border border-[#4A8FF6] text-base">
                  我要报名
                </BaseButton>
                <BaseButton style="bg-inherit text-[#4A8FF6] px-10 py-4 text-center underline rounded-full hover:shadow-none">
                  了解更多
                </BaseButton>
              </div>
            </div>
            <div
              data-aos="fade-left"
              className="col-span-12 lg:col-span-6 hidden sm:block"
            >
              <div className="w-full sm:mt-20 xl:mt-0">
                <img
                  src={require(`./assets/img/activity.png`)}
                  className="w-full"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Industry-leading security section --> */}
        <section className="w-full my-24">
          <div className="relative max-w-screen-xl px-8 mx-auto grid grid-cols-12 gap-x-6">
            <div data-aos="fade-right" className="col-span-12 lg:col-span-6">
              <div className="w-full">
                <img
                  src={require("./assets/img/cookies.png")}
                  className="w-full"
                  alt=""
                />
              </div>
            </div>
            <div
              data-aos="fade-left"
              className="col-span-12 lg:col-span-5 space-y-8 sm:space-y-6 mt-8 xl:px-8"
            >
              <h2 className="text-4xl font-semibold">付费1V1服务</h2>
              <ul className="space-y-8 sm:space-y-4">
                <LandingListItem title="全民红娘套餐。最低199元">
                  <p className="text-sm text-gray-700 leading-relaxed">
                    <div>服务人： 热心的群友</div>
                    <div>
                      服务介绍：进入“全民红娘”社群，发帖注明打赏金额，群友帮忙牵线后，给予群友打赏。
                    </div>
                  </p>
                </LandingListItem>
                3) 4）
                <LandingListItem title="单次线上认识套餐。400元">
                  <p className="text-sm text-gray-700 leading-relaxed">
                    <div>服务人: 官方红娘</div>
                    <div>
                      服务介绍:
                      一个月内不限量匹配，双方愿意线加微信，付款400元，官方引导3天线上互动沟通。
                    </div>
                  </p>
                </LandingListItem>
                <LandingListItem title="单次线下约见套餐。800元">
                  <p className="text-sm text-gray-700 leading-relaxed">
                    <div>服务人: 官方红娘</div>
                    <div>
                      服务介绍:
                      一个月内不限量匹配，双方愿意线下约见，付款后安排线下见面。
                    </div>
                  </p>
                </LandingListItem>
                <LandingListItem title="单次线下约见套餐。800元">
                  <p className="text-sm text-gray-700 leading-relaxed">
                    <div>包脱单服务。定金2000元</div>
                    <div>
                      服务人:专业红娘服务介绍:尽可能多尽可能快的安排1v1匹配约见，不限时不限量，恋爱脱单后再支付尾款（尾款根据自身需求所选择的服务套餐丰俭由人）
                    </div>
                  </p>
                </LandingListItem>
              </ul>
            </div>
          </div>
        </section>
        {/* <!-- Getting started section --> */}
        <section
          className="bg-trading-tools relative max-w-full sm:mx-4 xl:mx-10 my-24 shadow sm:rounded-2xl overflow-hidden"
          id="about"
        >
          <div className="w-full py-16 flex flex-col items-center">
            <h2
              data-aos="flip-down"
              className="text-3xl sm:text-4xl font-semibold text-center"
            >
              关注我们，最快速的脱单方式
            </h2>
            <div
              data-aos="fade-up"
              className="relative w-full flex flex-col lg:flex-row items-center justify-between space-y-12 lg:space-y-0 px-4 xl:px-10 mt-16 sm:mt-8"
            >
              {steps.map((step) => {
                return <LandingStep step={step} />;
              })}
              <img
                src={require("./assets/img/getting-started/arrow.png")}
                className="hidden lg:inline-block absolute top-32 left-64 xl:left-[22rem] w-24 xl:w-[9.5rem]"
                alt=""
              />
              <img
                src={require("./assets/img/getting-started/arrow.png")}
                className="hidden lg:inline-block absolute top-32 right-64 xl:right-[22rem] w-24 xl:w-[9.5rem]"
                alt=""
              />
            </div>
          </div>
        </section>
        {/* <!-- FAQ section --> */}
        <section className="w-full my-24" id="support">
          <BaseSection>
            <div
              data-aos="fade-right"
              data-aos-delay="150"
              className="col-span-12 lg:col-span-6"
            >
              <div className="w-full px-16">
                <img
                  src={require("./assets/img/faq.png")}
                  className="w-full"
                  alt=""
                />
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-delay="150"
              className="col-span-12 lg:col-span-6 px-4 sm:px-6 mt-8"
            >
              <span className="text-base text-gradient font-semibold uppercase mb-4 sm:mb-2">
                支持与帮助
              </span>
              <h2 className="text-3xl sm:text-4xl font-semibold mb-10 sm:mb-6">
                常被问到的问题
              </h2>

              <ul className="shadow-box">
                {accordions.map((accordion: any) => {
                  return <BaseAccordion accordion={accordion} />;
                })}
              </ul>
            </div>
          </BaseSection>
        </section>

        <div className="w-full my-10 flex justify-center">
          <a
            data-aos="flip-down"
            data-aos-delay="150"
            href="#"
            className="px-6 py-3 flex items-center space-x-2 bg-[#FAFAFA] hover:bg-gray-100 hover:shadow-md border border-[#DDDDDD] rounded-md text-gray-700"
          >
            <span>回到顶部</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-4"
            >
              <path
                fillRule="evenodd"
                d="M11.47 2.47a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06l-6.22-6.22V21a.75.75 0 01-1.5 0V4.81l-6.22 6.22a.75.75 0 11-1.06-1.06l7.5-7.5z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </div>
      </DefaultLayout>
    </div>
  );
}

export default App;

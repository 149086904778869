import React, { ReactNode } from "react";

interface SlotComponentProps {
  style: string;
  children: ReactNode;
  href?: string;
}

const BaseButton: React.FC<SlotComponentProps> = ({
  style,
  children,
  href,
}) => {
  return (
    <a href={href || "#about"}>
      <button
        className={`text-sm text-center w-full rounded-full hover:shadow-md hover:shadow-md/50 transition duration-300 ${style}`}
      >
        {children}
      </button>
    </a>
  );
};

export default BaseButton;
